body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gc-text-thin {
  font-weight: 100;
}

.gc-text-medium {
  font-weight: 300;
}

.gc-text-regular {
  font-weight: 400;
}

.gc-text-bold {
  font-weight: 900;
}
